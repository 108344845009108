'use strict';
const isDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    isAndroid = /Android/i.test(navigator.userAgent),
    isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent),
    isMobile = $(window).width() < 768,
    mobileWidth = 767,
    deviceWidth = 1024,
    isIE11 = !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/));
const FE = {
    global: {
        equalHeightByRow: (obj, notRunMobile) => {
            let widthTarget = 0;
            if ($(obj).length) {
                $(obj).height('auto');
                widthTarget = (notRunMobile === true) ? 768 : 0;
                if ($(window).width() >= widthTarget) {
                    var currentTallest = 0,
                        currentRowStart = 0,
                        rowDivs = [],
                        currentDiv = 0,
                        $el,
                        topPosition = 0;
                    $(obj).each(function() {
                        if ($(this).is(':visible') === true) {
                            $el = $(this);
                            topPosition = $el.offset().top;
                            if (currentRowStart !== topPosition) {
                                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                                    rowDivs[currentDiv].innerHeight(currentTallest);
                                }
                                rowDivs = [];
                                currentRowStart = topPosition;
                                currentTallest = $el.innerHeight();
                                rowDivs.push($el);
                            } else {
                                rowDivs.push($el);
                                currentTallest = (currentTallest < $el.innerHeight()) ? ($el.innerHeight()) : (currentTallest);
                            }
                            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                                rowDivs[currentDiv].innerHeight(currentTallest);
                            }
                        }
                    });
                }
            }
        },
        selectboxStyle: (name) =>{
            $(name).find('.dropdown-menu li a').on('click', function(){
                $(this).parent().parent().next().val($(this).text());
            });
        },
        detectDevices: () => {
            let a = isDevice === true ? ' device' : ' pc',
                b = isAndroid === true ? ' android' : ' not-android',
                c = isIos === true ? ' ios' : ' not-ios',
                d = isMobile ? ' mobile' : ' desktop',
                e = isIE11 ? ' ie11' : ' ',
                htmlClass = a + b + c + d + e;
            $('html').addClass(htmlClass);
            if (isDevice) {
                $('body').css('cursor', 'pointer');
            }
        },
        replaceImgToBackground: (img) => {
            $(img).each(function() {
                if ($(this).css('visibility') == 'visible') {
                    $(this).parent().css('background-image', 'url(' + $(this).attr('src') + ')');
                }
            });
        },
        dropDown: () => {
            $.each($('.style-select'), (index, value) => {
                if ($(this).find('select option:selected').text() !== '') {
                    $(this).find('span').text($(this).find('select option:selected').text());
                }
            });
            $('body').on('change', '.style-select select', function() {
                $(this).prev().text($(this).find('option:selected').text());
            });
        },
        clickOutside: (method, box, targetElement) => {
            $('html').on('click', 'body', e => {
                var container = $(box);
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    switch (method) {
                        case 'fade':
                            $(targetElement).stop().fadeOut(500);
                            break;
                        case 'slide':
                            $(targetElement).stop().slideUp();
                            break;
                        case 'active':
                            $(targetElement).stop().removeClass('active');
                            break;
                    }
                }
            });
        },
        clickOutsideNav: (method, box, targetElement) => {
            $('html').on('click', 'body', e => {
                var container = $(box);
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    switch (method) {
                        case 'fade':
                            $(targetElement).stop().fadeOut(500);
                            break;
                        case 'slide':
                            $(targetElement).stop().slideUp();
                            break;
                        case 'active':
                            $(targetElement).stop().removeClass('active');
                            break;
                    }
                    container.removeClass('active');
                }
            });
        },
        styleSelect: () => {
            $('select.styleSelect').each(function() {
                var select = $('<div>').append($(this).clone()).html(),
                    selectText = $(this).find('option:selected').text(),
                    selectTemplate =
                    '<div class="style-select">' +
                    '<span>' + selectText + '</span>' +
                    select +
                    '<div>';
                $(this).replaceWith(selectTemplate);
                $('.style-select > div:last-child').remove();
            });
        },
        sliderDetail: () => {
            $('.slider-detail .responsive').slick({
                infinite: false,
                arrows: true,
                autoplay: true,
                dots: true,
                slidesToShow: 1,
                lazyload: 'ondemand',
                autoplaySpeed: 5000
            });
            $('.product-gallery .responsive').slick({
                infinite: false,
                arrows: true,
                autoplay: true,
                dots: true,
                slidesToShow: 1,
                lazyload: 'ondemand',
                autoplaySpeed: 5000,
                responsive: [
                    {
                      breakpoint: 992,
                      settings: {
                        dots: true,
                        arrows: false
                      }
                    }
                ]
            });
        },
        specWrapper: () => {
            if($(window).width() >= 992){
                $('.spec-wrapper').masonry({
                  itemSelector: '.spec-group'
                });
            };
        },
        heightIframeVideo: (videoContent) =>{
            $(videoContent).css('height', $('.banner-slider').innerHeight());
        },
        tabsMenu: () => {
            $(document).on('click', '.tabs-control .tab-content .header-tab', function (e) {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active').next().slideUp();
                    let idTabMobile = $(this).next().attr('id');
                    $('.tabs-control .nav-tabs li').each(function () {
                        if ($(this).attr('href') == idTabMobile) {
                            $(this).removeClass('active');
                        }
                    });
                } else {
                    $(this).parent().find('.header-tab').each(function () {
                        $(this).removeClass('active').next().slideUp();
                    });
                    $(this).addClass('active').next().slideDown();
                    $('.tabs-control .nav-tabs li').removeClass('active')
                    let idTabMobile = $(this).next().attr('id');
                    $('.tabs-control .nav-tabs li').each(function () {
                        if ($(this).find('a').attr('href') == '#' + idTabMobile) {
                            $(this).addClass('active');
                        }
                    });
                }
            });
            $(document).on('click', '.tabs-control .nav-tabs li', function (e) {
                e.preventDefault();
                let idTabDesktop = $(this).find('a').attr('href');
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $(idTabDesktop).slideUp().prev().removeClass('active');
                } else {
                    $('.tabs-control .tab-content .header-tab').removeClass('active').next().slideUp();
                    $('.tabs-control .nav-tabs li').removeClass('active');
                    $(this).addClass('active');
                    $(idTabDesktop).slideDown().prev().addClass('active');
                }
            });
        },
        productNavigation: () => {
            if($('.class-check-offset').length){
                let topPosition = $('.class-check-offset').offset().top;
                if(topPosition <= $(document).scrollTop()){
                    $('.product-navigation').addClass('anchored');
                }else{
                    $('.product-navigation').removeClass('anchored');
                }
            };
        },
        mobileTab: () => {
            $('.mobile-tab-heading').on('click', function(){
                $(this).toggleClass('active');
                if($(this).hasClass('active')){
                    $('.mobile-tab-heading').removeClass('active');
                    $('.mobile-tab-body').slideUp();
                    $(this).addClass('active').next().slideDown();
                    $('.slider-detail .responsive').slick({
                        infinite: false,
                        arrows: true,
                        autoplay: true,
                        dots: true,
                        slidesToShow: 1,
                        lazyload: 'ondemand',
                        autoplaySpeed: 5000
                    });
                }else{
                    $('.slider-detail .responsive').slick('unslick');
                    $(this).next().slideUp();
                }
            });
        },
        scrollTo: (btnScrollTo) => {
            $(btnScrollTo).on('click', function(){
                let idTo = $(this).attr('data-taget');
                $('html').animate({
                    scrollTop: $(idTo).offset().top - 120
                }, 500);
            });
        },
        inputVideo: (btnPlay) => {
            $(btnPlay).on('click', function(){
                let parentContent = $(this).parent();
                let heightIframe = parentContent.height();
                let linkVideo = parentContent.addClass('added-video').find('.js-video-feature').attr('data-video') + '&autoplay=1';
                let iframeVideo = '<iframe allowfullscreen="1" allow="accelerometer; autoplay="1"; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" src="' + linkVideo + '" width="100%" height="100%" frameborder="0"></iframe>'
                parentContent.find('.js-video-feature').html(iframeVideo);
                parentContent.find('iframe').css('height', heightIframe);
            })
        },
        viewMore: (btnViewMore, contentView) => {
            $(btnViewMore).on('click', function(){
                $(this).parent().parent().find(contentView).show();
                FE.global.specWrapper();
                $(this).hide();
            });
        },
        viewMoreProduct: (btnViewMore, contentView) => {
            $(btnViewMore).on('click', function(){
                $(this).parent().parent().find(contentView).show();
                $(this).hide();
                FE.global.equalHeightByRow('.category-container .product-category .product-info .product-name');
                FE.global.equalHeightByRow('.category-container .product-category .product-info .img-content');
                FE.global.equalHeightByRow('.category-container .product-category .product-info');
                FE.global.equalHeightByRow('.category-container .product-category .list-function');
            });
        },
        mainMenu: () => {
            $('.header .navbar-nav .nav-link').on('click', function(){
                $(this).toggleClass('active');
                if($(this).hasClass('active')){
                    $('.header .navbar-nav .nav-link').removeClass('active');
                    $('.navbar-nav .dropdown-menu').slideUp();
                    $(this).addClass('active').next().slideDown();
                }else{
                    $(this).next().slideUp();
                }
            })
        },
        changeLanguage: () => {
            $('.en .dropdown-language .dropdown-menu .dropdown-item').on('click', function(){
                let addr = window.location.href;
                let indx = addr.lastIndexOf('.');
                let address = addr.substring(0, indx) + '-vn.html';
                window.location = address;
            });
            $('.vn .dropdown-language .dropdown-menu .dropdown-item').on('click', function(){
                let addr = window.location.href;
                let indx = addr.lastIndexOf('-');
                let address = addr.substring(0, indx) + '.html';
                window.location = address;
            })
        },
        backToTop: () => {
            $('.back-to-top').on('click', function(){
                $('html, body').animate({
                    scrollTop: 0
                }, 1000);
            });
        },
        init: () => {
            // FE.global.equalHeightByRow('.eqh');
            // FE.global.styleSelect();
            // FE.global.dropDown();
            FE.global.detectDevices();
            // FE.global.tabsMenu();
            FE.global.mainMenu();
            FE.global.clickOutsideNav('slide', '.navbar-nav .nav-link', '.navbar-nav .dropdown-menu');
            FE.global.backToTop();

        },
        loaded: () => {
            FE.global.equalHeightByRow('.category-container .product-category .product-info .product-name');
            FE.global.equalHeightByRow('.category-container .product-category .product-info .img-content');
            FE.global.equalHeightByRow('.category-container .product-category .product-info');
            FE.global.equalHeightByRow('.category-container .product-category .list-function');
            FE.global.sliderDetail();
            FE.global.heightIframeVideo('.banner-slider iframe');
            FE.global.specWrapper();
            FE.global.productNavigation();
            FE.global.scrollTo('.product-navigation-list .btn-link');
            FE.global.inputVideo('.video-wrapper-block .icon-play');
            FE.global.viewMore('.js-show-specs', '.spec-wrapper .spec-group');
            FE.global.replaceImgToBackground('.img-bg');
            FE.global.viewMoreProduct('.category-container .btn-show-more', '.product-category');
            FE.global.changeLanguage();
            FE.global.mobileTab();
        },
        resize: () => {
            FE.global.equalHeightByRow('.category-container .product-category .product-info .product-name');
            FE.global.equalHeightByRow('.category-container .product-category .product-info .img-content');
            FE.global.equalHeightByRow('.category-container .product-category .product-info');
            FE.global.equalHeightByRow('.category-container .product-category .list-function');
            FE.global.heightIframeVideo('.banner-slider iframe');
            FE.global.productNavigation();
            FE.global.specWrapper();
        },
        scroll: () => {
            FE.global.productNavigation();
        }
    },
    pages: {
        home: {
            init: () => {},
            loaded: () => {},
            resize: () => {}
        }
    }
};

$(() => {
    FE.global.init();
    FE.pages.home.init();
});

$(window).load(() => {
    FE.global.loaded();
    FE.pages.home.loaded();
});

// Window resize
var width = $(window).width();
var resize = 0;
$(window).resize(() => {
    var _self = $(this);
    resize++;
    setTimeout(() => {
        resize--;
        if (resize === 0) {
            // Done resize ...
            if (_self.width() !== width) {
                width = _self.width();
                // Done resize width ...
                FE.global.resize();
                FE.pages.home.resize();
            }
        }
    }, 100);
});

$(window).scroll(() => {
    FE.global.scroll();
});
